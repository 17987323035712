import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import Div from '../components/Div/Div.styles'
import ImgCoreLogin from '../images/core-login.svg'

import { Container } from '../components/styles/Layout.styles'
import Button from './../components/Button/Button'
import MuiField from './../components/MuiField'
import { 
  styledCoreLogin,
  header
} from '../components/styles/CoreLogin.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledCoreLogin}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <Div
              maxWidth={['100%']}
              alignItems={['center']}
              style={{
                margin: '0 auto',
                marginBottom: 30
              }}
            >
              <img
                src={ImgCoreLogin} 
                style={{
                  width: 100
                }}
              />
            </Div>
            <H4>Core Login</H4>
          </Column>
        </Row>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <span
              style={{
                display: 'block',
                marginBottom: 16
              }}
            >
              Já tem seu Core Login?
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <MuiField
                variant="outlined"
                label="Email"
                placeholder='Email'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Senha"
                type='password'
                placeholder='Senha'
                style={{
                  maxWidth: 328,
                  marginBottom: 24
                }}
              />
              <Button
                schema='secondary'
                color='white'
                variant='highEmphasis'
                width={['100%']}
                maxWidth={['130px']}
                style={{
                  display: 'flex',
                  border: 'none',
                  margin: '0 auto',
                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                }}
              >
                Entrar
              </Button>
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Registro Core' />
      <PageTemplate
        title='Registro Core'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
