import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledCoreLoginBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [50]
  })
)

export const styledCoreLogin = css`
  ${styledCoreLoginBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, 422, 680, 680],
    marginLeft: [0, 89, 200, 302],
    marginRight: [0, 89, 200, 302],
    marginTop: [16],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['center']
  })
)

export const header = css`
  display: flex;
  text-align: center;
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    display: grid;
  }
  & > span {
    font-size: 15.7px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.53;
    letter-spacing: 0.15px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }
`
